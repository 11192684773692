import { useEffect, useState } from 'react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from '~/components/ui/Card'
import Dialog, { DialogClose } from '~/components/Dialog'
import Input from '~/components/ui/Input'
import Button from '~/components/ui/Button'
import { LoaderCircle } from 'lucide-react'
import { useCreateOrganizationMutation } from 'gql/hooks/useCreateOrganizationMutation'

interface NewOrgModalParams {
  trigger: JSX.Element
}

const orgSchema = z.object({
  orgName: z.string().trim().min(1, 'Project name is required')
})

type OrgForm = z.infer<typeof orgSchema>

const NewOrgModal = ({ trigger }: NewOrgModalParams) => {
  const [isDisabled, setIsDisabled] = useState(true)

  const { createOrganization, loading: isCreating } = useCreateOrganizationMutation()

  const {
    register,
    watch,
    getValues,
    handleSubmit,
    reset,
    formState: { errors, isValid }
  } = useForm<OrgForm>({
    resolver: zodResolver(orgSchema),
    mode: 'onChange'
  })

  const orgNameInput = register('orgName')
  const orgName = watch('orgName')

  useEffect(() => {
    setIsDisabled(isCreating || !getValues('orgName') || !isValid)
  }, [getValues, isCreating, isValid, orgName])

  const onSubmit = (data: OrgForm) => {
    createOrganization({
      name: data.orgName
    })
      .then(() => {
        toast('Created Organization ' + data.orgName)
      })
      .catch((e) => {
        console.error('Error creating organization:', e)
        toast('Error creating organization: ' + e)
      })
      .finally(() => {
        reset()
        document.getElementById('create-org-cancel')?.click()
      })
  }

  return (
    <Dialog
      title="Create an Organization"
      className="p-0"
      overlay={
        <Card className="border-none shadow-none">
          <CardHeader>
            <CardTitle>Create an Organization</CardTitle>
            <CardDescription>
              Set up your organization and start inviting members.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <form
              id="create-organization-form"
              onSubmit={(event) => void handleSubmit(onSubmit)(event)}
            >
              <Input
                type="text"
                placeholder="Organization Name"
                {...register('orgName')}
                onChange={(_, event) => void orgNameInput.onChange(event)}
                error={errors.orgName?.message}
              />
            </form>
          </CardContent>
          <CardFooter>
            <div className="w-full gap-2 flex justify-end">
              <DialogClose>
                <Button id="create-org-cancel" variant="ghost">
                  Cancel
                </Button>
              </DialogClose>

              <Button type="submit" form="create-organization-form" disabled={isDisabled}>
                {isCreating && <LoaderCircle className="mr-2 h-5 w-5 animate-spin" />}
                Create
              </Button>
            </div>
          </CardFooter>
        </Card>
      }
    >
      {trigger}
    </Dialog>
  )
}

export default NewOrgModal
