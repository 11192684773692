import { httpClient } from '../httpClient'
import { type DrawingSetResponse } from '../types'

export interface DrawingSetsParams {
  projectId: number
  procoreCompanyId: number
}

export async function getDrawingSets(params: DrawingSetsParams) {
  if (params.procoreCompanyId === 0 || params.projectId === 0) {
    throw new Error('Company Id and Project Id are required')
  }

  const { procoreCompanyId, projectId } = params

  const response = await httpClient<DrawingSetResponse>(
    `/api/v2/procore/projects/${projectId}/drawing-sets?procore_company_id=${procoreCompanyId}`
  )

  return response
}
