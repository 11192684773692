import Badge from '../ui/Badge'
import { env } from '~/env'

const getEnvName = (): string | null => {
  const hostname = location.hostname

  if (hostname.includes('localhost')) return env.VITE_ENV

  const subdomains = hostname.split('.')

  if (subdomains.length <= 2) return null

  return subdomains[0]
}

export function EnvBadge() {
  const envName = getEnvName()

  if (!envName) return null

  return (
    <div className="fixed bottom-4 right-4">
      <Badge
        variant="neutral"
        className="min-w-20 min-h-7 bg-red-500 text-white shadow-md rounded-md font-semibold"
      >
        <p className="w-full text-center">{envName}</p>
      </Badge>
    </div>
  )
}
