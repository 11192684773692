import { LoaderCircle } from 'lucide-react'
import { cva, type VariantProps } from 'class-variance-authority'

export const loadingVariants = cva(
  'fixed w-full h-screen z-40 top-0 grid place-items-center',
  {
    variants: {
      mode: {
        light: 'bg-white',
        dark: 'bg-[#333333]'
      }
    },
    defaultVariants: {
      mode: 'light'
    }
  }
)

export const spinnerVariants = cva('h-8 w-8 opacity-60 animate-spin', {
  variants: {
    mode: {
      light: 'text-content',
      dark: 'text-[#e64486]'
    }
  },
  defaultVariants: {
    mode: 'light'
  }
})

export interface LoadingProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof loadingVariants> {
  label?: string
}

export default function Loading({ mode, label }: LoadingProps) {
  return (
    <div className={loadingVariants({ mode })}>
      <div className="flex flex-col items-center justify-center">
        <LoaderCircle className={spinnerVariants({ mode })} />
        {label && (
          <p
            className={`text-sm text-center ${mode === 'dark' ? 'text-white' : 'text-content'}`}
          >
            {label}
          </p>
        )}
      </div>
    </div>
  )
}
