import { Link, useLocation, useParams } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import { useUser } from '~/graphql/hooks/useUser'
import logo from 'assets/images/mbue_logo_magenta.png'
import { Avatar, AvatarFallback } from 'components/ui/Avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from 'components/ui/DropdownMenu'

import BreadcrumbNav from './BreadcrumbNav'

import Tooltip from '~/components/ui/Tooltip'
import { useEffect, useState } from 'react'
import AccountSettingsDialog from '../Account/AccountSettingsDialog'
import { UserRound } from 'lucide-react'
import { useVariableValue } from '@devcycle/react-client-sdk'

const TopNav = () => {
  const isPricingPageEnabled = useVariableValue('pricing-page-enabled', false)
  const { pathname } = useLocation()
  const { logout } = useAuth()
  const { orgId } = useParams<{ orgId: string }>()
  const [scriptInitialized, setScriptInitialized] = useState<boolean>(false)
  const [accountSettingsOpen, setAccountSettingsOpen] = useState<boolean>(false)

  const { user } = useUser()

  const feedbackHandler = () => {
    document.getElementById('atlwdg-trigger')?.click()
  }

  useEffect(() => {
    if (!scriptInitialized) {
      setScriptInitialized(true)
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src =
        'https://mbue.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/xtxyma/b/6/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=7ae7cc5e'
      document.body.appendChild(script)
    }
  }, [scriptInitialized])

  const isUserFromProcore = location.pathname.startsWith('/procore')

  if (pathname === '/org') return null

  return (
    <header className="fixed w-full z-50 top-0 h-14 bg-content px-6 flex justify-between items-center">
      <div className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
        <Link
          to={user ? `/org/${orgId ?? user?.orgs[0].id}/projects` : '/'}
          className="flex items-center gap-2 text-lg font-semibold md:text-base"
          onClick={(event) => isUserFromProcore && event.preventDefault()}
        >
          <img src={logo} alt="mbue logo" className="h-6" />
          <span className="sr-only">mbue</span>
        </Link>

        {pathname.startsWith('/org') && (
          <BreadcrumbNav isUserFromProcore={isUserFromProcore} />
        )}
      </div>

      {!isUserFromProcore && (
        <>
          <div className="flex items-center gap-6">
            <div className="flex items-center gap-4">
              <Tooltip overlay="Report a Bug">
                <button
                  type="button"
                  className="w-8 h-8 hover:bg-content-secondary border-[2px] rounded-full border-highlight font-bold text-highlight"
                  onClick={() => feedbackHandler()}
                >
                  ?
                </button>
              </Tooltip>

              {isPricingPageEnabled && orgId && (
                <Link
                  to={`/org/${orgId}/pricing`}
                  className="grid place-content-center px-3 h-8 bg-gradient-splash text-fundation rounded"
                >
                  <span className="text-sm">Upgrade</span>
                </Link>
              )}
            </div>

            <div className="flex items-center gap-4">
              <p className="text-fundation text-sm">{`${user?.firstName} ${user?.lastName}`}</p>
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <Avatar className="w-8 h-8">
                    <AvatarFallback className="w-8 h-8 bg-divider">
                      {user?.firstName && user.lastName ? (
                        (user.firstName[0] + user.lastName[0]).toUpperCase()
                      ) : (
                        <UserRound />
                      )}
                    </AvatarFallback>
                  </Avatar>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="mx-1">
                  <DropdownMenuItem>
                    <button onClick={() => setAccountSettingsOpen(true)}>
                      My Account
                    </button>
                  </DropdownMenuItem>
                  {user?.isAdmin && (
                    <>
                      <DropdownMenuItem>
                        <Link to={user ? `/admin/jobs` : '/'}>Admin View</Link>
                      </DropdownMenuItem>
                      {/* <div className="flex gap-2 relative items-center rounded-sm px-2 py-1.5 text-sm">
                    <Switch
                      id="smart-overlay-enabled"
                      checked={isSmartOverlayEnabled}
                      onCheckedChange={(checked) => setIsSmartOverlayEnabled(checked)}
                      className="h-4 w-6"
                      toggleClassName="w-2.5 h-2.5 data-[state=checked]:translate-x-2.5"
                    />
                    <Label
                      htmlFor="smart-overlay-enabled"
                      className="text-sm font-normal cursor-pointer"
                    >
                      Smart Overlay
                    </Label>
                  </div> */}
                    </>
                  )}
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onClick={() => void logout()}>
                    Log Out
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
          <AccountSettingsDialog
            open={accountSettingsOpen}
            setOpen={(isOpen) => setAccountSettingsOpen(isOpen)}
          />
        </>
      )}
    </header>
  )
}

export default TopNav
