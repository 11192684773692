import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { pickBy, isEqual, mapValues } from 'lodash'
import { useForm } from 'react-hook-form'
import Button from '~/components/ui/Button'
import Checkbox from '~/components/ui/Checkbox'
import Input from '~/components/ui/Input'
import {
  Select,
  SelectTrigger,
  SelectGroup,
  SelectItem,
  SelectContent,
  SelectValue
} from '~/components/ui/Select'
import { defaultValues, jobFilterSchema, JobFormValues } from './types'
import { zodResolver } from '@hookform/resolvers/zod'

export interface AdminJobsFilter {
  name: string
  page: number
  limit: number
  filters: {
    employee: boolean
    deleted: boolean
    jobId: string
    email: string
    emailSearchType: string
    jobType: string
  }
}

interface JobsFilterFormParams {
  handleSubmitCallback: (data: JobFormValues) => void
}

export const JobsFilterForm = (props: JobsFilterFormParams) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<JobFormValues>({
    defaultValues: {
      ...defaultValues,
      ...mapValues(Object.fromEntries(searchParams), (val) =>
        val === 'false' ? false : val
      )
    },
    resolver: zodResolver(jobFilterSchema)
  })

  const jobId = register('jobId')
  const email = register('email')
  const employee = register('employee')
  const deleted = register('deleted')
  const formValues = getValues()

  useEffect(() => {
    handleSubmitCallback(formValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmitCallback = (data: JobFormValues) => {
    const nonDefaultChanges = pickBy(
      mapValues(data, (val) => (val !== undefined ? val.toString() : null)),
      (v: string, k: string) =>
        !isEqual(defaultValues[k as keyof JobFormValues].toString(), v)
    )
    setSearchParams({ ...nonDefaultChanges })
    props.handleSubmitCallback(data)
  }

  return (
    <>
      <form
        className="bg-muted p-2 rounded-md"
        onSubmit={(event) => void handleSubmit(handleSubmitCallback)(event)}
      >
        <div className="my-4 mx-auto grid gap-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 max-w-3xl">
          <div className="flex flex-col gap-1">
            <label htmlFor="jobId" className="text-sm font-medium">
              Job ID
            </label>
            <Input
              id="jobId"
              type="text"
              {...jobId}
              onChange={(_, event) => void jobId.onChange(event)}
              className="w-full"
            />
          </div>

          <div className="col-span-2 flex flex-col gap-1">
            <label htmlFor="email" className="text-sm font-medium">
              Email
            </label>
            <Input
              id="email"
              type="text"
              {...email}
              onChange={(_, event) => void email.onChange(event)}
              error={errors.email?.message}
              className="w-full"
            />
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="emailMatch" className="text-sm font-medium">
              Email Match Type
            </label>
            <Select
              onValueChange={(value) => setValue('emailSearchType', value)}
              defaultValue={formValues.emailSearchType}
            >
              <SelectTrigger className="p-3 w-full">
                <SelectValue placeholder="" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="partial">partial</SelectItem>
                  <SelectItem value="exact">exact</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="limit" className="text-sm font-medium">
              Limit
            </label>
            <Select
              onValueChange={(value) => setValue('pageLimit', value)}
              defaultValue={formValues.pageLimit}
            >
              <SelectTrigger className="p-3 w-full" id="limit">
                <SelectValue placeholder="" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="10">10</SelectItem>
                  <SelectItem value="25">25</SelectItem>
                  <SelectItem value="50">50</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="type" className="text-sm font-medium">
              Type
            </label>
            <Select defaultValue="all">
              <SelectTrigger id="type">
                <SelectValue placeholder="Select type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All</SelectItem>
                <SelectItem value="drawing">Drawing</SelectItem>
                <SelectItem value="text">Text</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className=" col-span-3 flex flex-col gap-1">
            <label htmlFor="type" className="">
              &nbsp;
            </label>
            <div className="h-8 flex gap-4 items-center">
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="hideEmployees"
                  onCheckedChange={(value) => setValue('employee', value ? true : false)}
                  defaultChecked={formValues.employee}
                  {...employee}
                />
                <label
                  htmlFor="hideEmployees"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Hide Employees
                </label>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="hideDeleted"
                  onCheckedChange={(value) => setValue('deleted', value ? true : false)}
                  defaultChecked={formValues.deleted}
                  {...deleted}
                />
                <label
                  htmlFor="hideDeleted"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Hide Deleted
                </label>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="type" className="">
              &nbsp;
            </label>
            <div className="h-8 flex gap-4 items-center">
              <Button type="submit" className="w-full bg-primary">
                Search
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
