import { env } from '~/env'
import { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { storage } from '~/utilities/storageUitls'
import logo from 'assets/images/mbue_logo_magenta.png'
import procoreLogo from 'assets/images/procore-logo.png'

const PROCORE_CLIENT_ID = env.VITE_PROCORE_CLIENT_ID
const PROCORE_REDIRECT_URI = env.VITE_PROCORE_REDIRECT_URI
const PROCORE_AUTH_URL = `${env.VITE_PROCORE_AUTH_URL}/oauth/authorize`

export default function Login() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const state = uuidv4()
  const companyId = searchParams.get('company_id') ?? ''
  const projectId = searchParams.get('project_id') ?? ''

  useEffect(() => {
    sessionStorage.setItem('oauth_state', state)
    storage.set('procore_company_id', companyId)
    storage.set('procore_project_id', projectId)
  }, [companyId, projectId, state])

  useEffect(() => {
    const token = storage.getJSON('access_token') as string
    if (token) {
      const mbueOrgId = storage.get('mbue_org_id')
      const mbueProjectId = storage.get('mbue_project_id')
      navigate(
        `/procore?company_id=${companyId}&project_id=${projectId}&mbue_org_id=${mbueOrgId}&mbue_project_id=${mbueProjectId}`
      )
    }
  }, [companyId, navigate, projectId])

  return (
    <div className="w-full h-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="flex gap-6 flex-col items-center ">
            <img className="w-48" src={logo} alt="mbue" />
            <h1 className="text-3xl font-bold">Login</h1>
          </div>

          <div className="w-full flex justify-center">
            <a
              href={`${PROCORE_AUTH_URL}?client_id=${PROCORE_CLIENT_ID}&redirect_uri=${PROCORE_REDIRECT_URI}&response_type=code&state=${state}`}
              className="rounded inline-flex items-center gap-2 bg-[#FF5201] text-white hover:bg-[#E14504] py-2 p-4 w-full max-w-72 text-sm"
            >
              <img
                src={procoreLogo}
                alt="procore logo"
                className="w-4 h-4 bg-white rounded"
              />
              <span className="mx-auto">Login with Procore</span>
            </a>
          </div>

          <div className="grid gap-4">
            <div className="w-full text-center">
              <p className="text-xs">
                I agree to{' '}
                <a
                  href="https://docsend.com/view/b6uajeme9jrr4w7t"
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary underline underline-offset-2"
                >
                  terms of service
                </a>{' '}
                and{' '}
                <a
                  href="https://docsend.com/view/598zsppf6n9vc5r7"
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary underline underline-offset-2"
                >
                  privacy statements
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden bg-muted lg:block">
        <img
          src="https://images.squarespace-cdn.com/content/v1/61dca87d8e88f25fc40b0493/2d2a02eb-5b1f-43e7-b74d-ccca82e82897/AdobeStock_601259045.jpeg?format=1500w"
          alt="Image"
          width="1920"
          height="1080"
          className="h-screen w-full object-cover dark:brightness-[0.2] dark:grayscale drag-none -scale-x-100 object-[-335px] 2xl:object-[-450px]"
        />
      </div>
    </div>
  )
}
