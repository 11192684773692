import { useState, PropsWithChildren, forwardRef } from 'react'
import { SmartReportQuery } from '~/graphql/hooks/useSmartReportQuery'
import { classNames } from '~/utilities'
import Markdown from 'react-markdown'

import { ScanSearchIcon, ChevronUpIcon, ChevronDownIcon } from 'lucide-react'
import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from '@tanstack/react-table'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableHeader
} from '~/components/ui/Table'
import Badge, { BadgeProps } from '~/components/ui/Badge'
import Tooltip from '../ui/Tooltip'
import { ColumnConfig } from './SmartReportingTypes'

const HeaderCell = ({
  children,
  column,
  notSort
}: PropsWithChildren<{
  column: Column<Record<string, string | number>, unknown>
  notSort?: boolean
}>): JSX.Element => {
  return (
    <button
      className="w-full"
      onClick={
        notSort ? undefined : () => column.toggleSorting(column.getIsSorted() === 'asc')
      }
    >
      <div className="flex items-center gap-1">
        <span>{children}</span>
        {!notSort && (
          <div className="flex flex-col justify-center items-center">
            <ChevronUpIcon
              size={13}
              className={`${column.getIsSorted() === 'asc' ? 'text-black' : 'text-disabled'} translate-y-[2px]`}
            />
            <ChevronDownIcon
              size={13}
              className={`${column.getIsSorted() === 'desc' ? 'text-black' : 'text-disabled'} -translate-y-[2px]`}
            />
          </div>
        )}
      </div>
    </button>
  )
}

const Cell = forwardRef<HTMLDivElement, PropsWithChildren<{ className?: string }>>(
  ({ className, children }, ref) => (
    <div
      ref={ref}
      className={classNames('w-full overflow-hidden text-nowrap', className)}
    >
      {children}
    </div>
  )
)

Cell.displayName = 'Cell'

const columnConfigs: Record<string, ColumnConfig> = {
  // Handle pageIndex specially to display as Page Number (value + 1)
  pageIndex: {
    displayName: 'Page #',
    cellRenderer: (value: number | string) => (
      <Cell className="text-center">{parseInt(value.toString()) + 1}</Cell>
    ),
    maxSize: 50
  },

  // Handle priority with the Badge component
  priority: {
    displayName: 'Priority',
    cellRenderer: (value: string | number) => (
      <Cell className="flex justify-left">
        <Badge
          {...(getImpact(value.toString()) as BadgeProps)}
          className="w-[84px] flex justify-center"
        />
      </Cell>
    ),
    maxSize: 60
  },

  // Handle preview column (which uses pageIndex as its data source)
  preview: {
    displayName: 'Preview',
    cellRenderer: (value: string | number) => (
      <Cell className="max-w-full flex justify-center">
        <a
          href={`${location.pathname}/view?pageNumber=${parseInt(value.toString()) + 1}`}
        >
          <ScanSearchIcon size={22} className="text-primary" />
        </a>
      </Cell>
    ),
    maxSize: 50
  },

  // Handle description with Markdown and Tooltip
  description: {
    displayName: 'Description',
    cellRenderer: (value: string | number) => (
      <Cell className="max-w-full truncate max-h">
        <Tooltip
          overlay={
            <div className="max-w-[500px] max-h-[300px] overflow-y-auto text-wrap">
              <Markdown>{value.toString()}</Markdown>
            </div>
          }
          className="border border-content bg-[#333333] text-white"
          side="bottom"
          align="start"
        >
          <span>{value}</span>
        </Tooltip>
      </Cell>
    ),
    maxSize: 250
  },

  // Handle sheetTitle with capitalization
  sheetTitle: {
    displayName: 'Sheet Title',
    cellRenderer: (value: string | number) => (
      <Cell className="max-w-full truncate">
        <span className="capitalize">{(value.toString() ?? '').toLowerCase()}</span>
      </Cell>
    ),
    maxSize: 150
  },

  // Handle sectionNumber
  sectionNumber: {
    displayName: 'Section #',
    cellRenderer: (value: string | number) => (
      <Cell className="flex justify-left">
        <span className="capitalize">{value ?? '-'}</span>
      </Cell>
    ),
    maxSize: 60
  }
}

// Define the custom sort order for categories
// Added -> Removed -> Modified -> Any other values
const getCategorySortValue = (category: string): number => {
  switch (category?.toLowerCase()) {
    case 'added':
      return 0
    case 'deleted':
      return 1
    case 'modified':
      return 2
    default:
      return 100 // Return a base value to ensure these come after the special cases
  }
}

// Create a default column definition generator for unknown columns
const createDefaultColumnDefinition = (
  columnKey: string
): ColumnDef<Record<string, string | number>> => {
  // Convert the column key to a display name (e.g., "pageIndex" -> "Page Index")
  const displayName = columnKey
    .replace(/([A-Z])/g, ' $1') // Insert a space before all capital letters
    .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter

  return {
    accessorKey: columnKey,
    header: ({ column }) => {
      return <HeaderCell column={column}>{displayName}</HeaderCell>
    },
    cell: (info) => {
      const value = info.getValue() as string | number
      return <Cell>{value?.toString() || '-'}</Cell>
    },
    maxSize: 100 // Default size
  }
}

// Create a column definition based on column key, handling special cases
const createColumnDefinition = (
  columnKey: string,
  reportType: 'drawing' | 'text' = 'drawing'
): ColumnDef<Record<string, string | number>> => {
  // Special case for category column with custom sorting
  if (columnKey === 'category') {
    return {
      accessorKey: columnKey,
      header: ({ column }) => {
        return (
          <HeaderCell column={column}>
            {columnConfigs[columnKey]?.displayName || 'Category'}
          </HeaderCell>
        )
      },
      cell: (info) => {
        const value = info.getValue() as string | number
        return <Cell>{value?.toString() || '-'}</Cell>
      },
      sortingFn: (rowA, rowB, columnId) => {
        const valueA: string = rowA.getValue(columnId)
        const valueB: string = rowB.getValue(columnId)

        const sortValueA = getCategorySortValue(valueA)
        const sortValueB = getCategorySortValue(valueB)

        // If they have different priority values, sort by those
        if (sortValueA !== sortValueB) {
          return sortValueA - sortValueB
        }

        // If they have the same priority (both in the "default" case), sort alphabetically
        return (valueA?.toLowerCase() || '').localeCompare(valueB?.toLowerCase() || '')
      },
      maxSize: columnConfigs[columnKey]?.maxSize ?? 100
    }
  }

  // Special case for 'preview' which uses pageIndex as its data source
  if (columnKey === 'preview') {
    const handler = columnConfigs.preview
    return {
      accessorKey: 'pageIndex',
      id: 'preview',
      header: () => <div className="w-full text-center">{handler.displayName}</div>,
      cell: (info) => {
        try {
          const value = info.getValue() as string | number
          return handler.cellRenderer(value)
        } catch (error) {
          console.error(`Error rendering preview column:`, error)
          return <Cell className="text-gray-400">-</Cell>
        }
      },
      maxSize: handler.maxSize ?? (reportType === 'drawing' ? 50 : 35)
    }
  }

  // Handle other special columns
  if (columnConfigs[columnKey]) {
    const handler = columnConfigs[columnKey]
    return {
      accessorKey: columnKey,
      header: ({ column }) => {
        return <HeaderCell column={column}>{handler.displayName ?? columnKey}</HeaderCell>
      },
      cell: (info) => {
        try {
          const value = info.getValue() as string | number
          // Check if value is undefined or null
          if (value === undefined || value === null) {
            return <Cell className="text-gray-400">-</Cell>
          }
          return handler.cellRenderer(value)
        } catch (error) {
          console.error(`Error rendering ${columnKey} column:`, error)
          return <Cell className="text-gray-400">-</Cell>
        }
      },
      maxSize: handler.maxSize ?? 100
    }
  }

  // Default handling for unknown columns
  return createDefaultColumnDefinition(columnKey)
}

const getColumns = (
  reportType: 'drawing' | 'text' = 'drawing',
  includedColumns: string[] = []
): ColumnDef<Record<string, string | number>>[] => {
  const columnsToUse = [...includedColumns]
  // Add preview column to be second to last column.
  const previewPosition = Math.max(0, columnsToUse.length - 1)
  columnsToUse.splice(previewPosition, 0, 'preview')
  return columnsToUse.map((columnKey) => createColumnDefinition(columnKey, reportType))
}

const SmartReporting = ({
  data,
  reportType
}: {
  data: SmartReportQuery['smartReport']
  reportType: 'drawing' | 'text'
}) => {
  // Initialize with default sorting by category, if it exists
  const [sorting, setSorting] = useState<SortingState>(() => {
    if (data.includedColumns.includes('category')) {
      return [
        {
          id: 'category',
          desc: false
        }
      ]
    }
    return []
  })

  // Use the includedColumns from the data to determine which columns to display
  const columns = getColumns(reportType, data.includedColumns)

  const table = useReactTable({
    data: data.changes ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting
    }
  })

  if (!data) return null

  return (
    <div className="relative w-full rounded-xl border max-h-[calc(100%_-_153px)] overflow-y-auto">
      <Table className="min-w-full table-auto" asChild>
        <TableHeader className="bg-gray-50 sticky top-0 z-10">
          <TableRow>
            {table.getHeaderGroups()[0].headers.map((header) => (
              <TableHead
                key={header.id}
                style={{ maxWidth: `${header.column.columnDef.maxSize}px` }}
                className="py-2 px-2"
              >
                {flexRender(header.column.columnDef.header, header.getContext())}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody className="bg-white divide-y divide-gray-200">
          {data.changes.length > 0 &&
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} className="hover:bg-gray-50">
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    className="py-2 px-2"
                    style={{ maxWidth: `${cell.column.columnDef.maxSize}px` }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          {data.changes.length === 0 && (
            <TableRow>
              <TableCell colSpan={table.getAllColumns().length} className="text-center">
                No changes found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}

export default SmartReporting

function getImpact(impact: string): { label: string; variant: string } {
  if (!impact) return { label: 'Unspecified', variant: 'neutral' }
  if (impact === 'high') return { label: 'High', variant: 'error' }
  if (impact === 'medium') return { label: 'Medium', variant: 'warning' }
  return { label: 'Low', variant: 'success' }
}
