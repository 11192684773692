import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator
} from '../ui/Breadcrumb'
import { HomeIcon } from 'lucide-react'
import logo from 'assets/images/mbue_logo_magenta.png'

export default function ProcoreHeader() {
  return (
    <header className="fixed w-full z-50 top-0 h-12 bg-content px-6 flex justify-between items-center">
      <div className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
        <figure className="flex items-center gap-2 text-lg font-semibold md:text-base">
          <img src={logo} alt="mbue logo" className="h-6" />
          <span className="sr-only">mbue</span>
        </figure>
        <ProcoreBreadcrumb />
      </div>
    </header>
  )
}

export function ProcoreBreadcrumb() {
  return (
    <Breadcrumb className="text-fundation">
      <BreadcrumbList>
        <BreadcrumbItem className="text-fundation">
          <HomeIcon size={16} />
        </BreadcrumbItem>
        <BreadcrumbSeparator className="text-fundation" />
        <BreadcrumbItem className="text-fundation">Reviews</BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  )
}
