import { useEffect, useRef } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useLocalStorage } from '@uidotdev/usehooks'
import { useProcoreAuth } from '~/hooks/procoreHooks'
import { storage } from '~/utilities/storageUitls'

import Loading from '~/components/ui/Loading'

export default function Callback() {
  const authAttempt = useRef<boolean>(false)
  const navigate = useNavigate()
  const [, setToken] = useLocalStorage('access_token', '')
  const [searchParams] = useSearchParams()

  const state = searchParams.get('state')
  const code = searchParams.get('code') ?? ''

  const {
    data,
    status,
    refetch: procoreAuth
  } = useProcoreAuth({
    authCode: code,
    companyId: storage.getNumber('procore_company_id'),
    projectId: storage.getNumber('procore_project_id')
  })

  useEffect(() => {
    const token = storage.getJSON('access_token') as string
    if (token) {
      const companyId = storage.get('procore_company_id')
      const projectId = storage.get('procore_project_id')
      const mbueOrgId = storage.get('mbue_org_id')
      const mbueProjectId = storage.get('mbue_project_id')
      navigate(
        `/procore?company_id=${companyId}&project_id=${projectId}&mbue_org_id=${mbueOrgId}&mbue_project_id=${mbueProjectId}`
      )
    }
  }, [navigate])

  // Validate OAuth state
  useEffect(() => {
    const companyId = storage.get('procore_company_id')
    const projectId = storage.get('procore_project_id')
    const oauth_state = sessionStorage.getItem('oauth_state')

    const hasValidState = state && oauth_state && state === oauth_state
    const hasValidIds = companyId && projectId

    function redirectToLogin() {
      navigate(`/procore/login?company_id=${companyId}&project_id=${projectId}`)
    }

    if (!hasValidIds) {
      console.error('Procore Company ID and Project ID are required')
      redirectToLogin()
      return
    }

    if (!hasValidState) {
      console.error('Invalid OAuth state')
      redirectToLogin()
      return
    }
  }, [state, navigate])

  // Handle authentication
  useEffect(() => {
    if (!code || authAttempt.current) return

    authAttempt.current = true
    void procoreAuth()
  }, [code, procoreAuth])

  useEffect(() => {
    const companyId = storage.get('procore_company_id')
    const projectId = storage.get('procore_project_id')
    if (status === 'error') {
      navigate(`/procore/login?company_id=${companyId}&project_id=${projectId}`)
    }

    if (status === 'success' && data) {
      setToken(data.token)
      storage.set('mbue_project_id', data.project_id.toString())
      storage.set('mbue_org_id', data.org_id.toString())
      navigate(
        `/procore?company_id=${companyId}&project_id=${projectId}&mbue_org_id=${data.org_id}&mbue_project_id=${data.project_id}`
      )
    }
  }, [data, navigate, setToken, status])

  const loadingMessage =
    status === 'pending' ? 'Authenticating with Procore...' : 'Initializing...'

  return <Loading label={loadingMessage} />
}
