import { createRoot } from 'react-dom/client'
import { Core, WebViewerInstance } from '@pdftron/webviewer'
import { JobManifest } from '~/graphql/hooks/useJobManifestQuery'
import DualScrollHeaderButton from '~/components/WebviewerComponent/DualScrollButton'
import { Switch } from '~/components/ui/Switch'
import Label from '~/components/ui/Label'

let dropdownContainer: HTMLDivElement | null = null
let inputSearch: HTMLInputElement | null = null

const debounceSearch = (fn: (value: string) => void, delay: number) => {
  let timeoutId: NodeJS.Timeout
  return (value: string) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn(value), delay)
  }
}

export const getPageLabel = (currentPage: number, jobManifest: JobManifest) => {
  let label = `Page ${currentPage}`
  const canRenderPageLabel =
    jobManifest.matches.length > 0 &&
    jobManifest.matches[currentPage - 1].revisedSheetNumber &&
    jobManifest.matches[currentPage - 1].revisedSheetTitle

  if (canRenderPageLabel) {
    label += ` | ${
      jobManifest.matches[currentPage - 1].revisedSheetNumber
    } - ${jobManifest.matches[currentPage - 1].revisedSheetTitle}`

    if (label.length > 60) {
      label = label.substring(0, 40) + '...'
    }
  }

  return label
}

const pageListItem = (iframeDoc: Document, label: string, onClick: () => void) => {
  const style = document.createElement('style')
  style.textContent = `
  .mbue-page-search-list-item {
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    border-radius: 4px;
    padding-inline: 8px;
    display: flex;
    align-items: center;
  }

  .mbue-page-search-list-item:hover {
    background-color: #4b4b4b;
    cursor: pointer;
  }

  .mbue-page-search-list-item span {
    font-size: 12px;
  }
`
  iframeDoc.head.appendChild(style)
  const pageListItem = document.createElement('li')
  pageListItem.classList.add('mbue-page-search-list-item')

  pageListItem.innerHTML = `
  <div style="font-size: 14px; width: 100%; overflow: hidden; text-overflow: ellipsis;" tabindex="0">
    <span style="text-wrap: nowrap;">${label}</span>
  </div>`

  pageListItem.addEventListener('click', onClick)
  pageListItem.addEventListener('keydown', (e) => {
    if (e.key === 'Enter') {
      onClick()
    }
  })
  return pageListItem
}

const pageSearchDropdown = (
  webviewerInstance: WebViewerInstance,
  iframeDoc: Document,
  jobManifest: JobManifest,
  totalPages: number,
  updatePageLabel: (pageNumber: number) => void
) => {
  const setCurrentPage = (pageNumber: number, smoothScroll: boolean) => {
    webviewerInstance.Core.documentViewer.setCurrentPage(pageNumber, smoothScroll)
  }
  const style = document.createElement('style')
  style.textContent = `
    .mbue-page-search-dropdown {
      background-color: #1e1e1e;
      border-radius: 4px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      box-sizing: border-box;
      display: none;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      max-height: 270px;
      // left: 50%;
      padding: 8px 0;
      position: fixed;
      top: 40px;
      // transform: translate(-50%, 0);
      width: 350px;
      z-index: 1000;
    }

    .mbue-page-search-dropdown--visible {
      display: flex;
    }

    .mbue-page-search-input {
      background-color: rgb(51, 51, 51);
      border-radius: 4px;
      border: 1px solid #485056;
      box-sizing: border-box;
      color: rgb(248, 249, 250);
      font-size: 12px;
      height: 30px;
      outline: none;
      padding: 4px 8px;
      width: calc(100% - 16px);
    }

    .mbue-page-search-list {
      box-sizing: border-box;
      flex-grow: 1;
      list-style-type: none;
      margin: 0;
      overflow-y: auto;
      padding: 0;
      width: 100%;
    }
  `
  iframeDoc.head.appendChild(style)

  const pageLabels = new Array(totalPages).fill(null).map((_, index) => {
    return pageListItem(iframeDoc, getPageLabel(index + 1, jobManifest), () => {
      setCurrentPage(index + 1, false)
      updatePageLabel(index + 1)
    })
  })

  dropdownContainer = document.createElement('div')
  dropdownContainer.classList.add('mbue-page-search-dropdown')

  const handleTabKey = (e: KeyboardEvent) => {
    if (!dropdownContainer?.classList.contains('mbue-page-search-dropdown--visible'))
      return

    const focusableElements = dropdownContainer.querySelectorAll('input, [tabindex="0"]')
    const firstElement = focusableElements[0] as HTMLElement
    const lastElement = focusableElements[focusableElements.length - 1] as HTMLElement

    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault()
      const currentElement = iframeDoc.activeElement
      const listItems = Array.from(focusableElements).slice(1)

      if (currentElement === inputSearch) {
        if (e.key === 'ArrowDown') {
          ;(listItems[0] as HTMLElement).focus()
        }
        return
      }

      const currentIndex = listItems.indexOf(currentElement as HTMLElement)
      if (currentIndex === -1) return

      let nextIndex
      if (e.key === 'ArrowDown') {
        nextIndex = currentIndex === listItems.length - 1 ? 0 : currentIndex + 1
      } else {
        nextIndex = currentIndex === 0 ? listItems.length - 1 : currentIndex - 1
      }

      ;(listItems[nextIndex] as HTMLElement).focus()
    }

    if (e.key === 'Tab') {
      if (e.shiftKey) {
        if (iframeDoc.activeElement === firstElement) {
          e.preventDefault()
          lastElement.focus()
        }
      } else {
        if (iframeDoc.activeElement === lastElement) {
          e.preventDefault()
          firstElement.focus()
        }
      }
    }

    // Close dropdown on Escape
    if (e.key === 'Escape') {
      dropdownContainer?.classList.remove('mbue-page-search-dropdown--visible')
    }
  }
  dropdownContainer.addEventListener('keydown', handleTabKey)

  inputSearch = document.createElement('input')
  inputSearch.placeholder = 'Type the page number or sheet title...'
  inputSearch.classList.add('mbue-page-search-input')
  inputSearch.type = 'text'

  const handleSearch = (value: string) => {
    if (!value) {
      pagesList.innerHTML = ''
      pagesList.append(...pageLabels)
      return
    }

    const filteredPageLabels = pageLabels.filter((pageLabel) => {
      return pageLabel.innerText.toLowerCase().includes(value.toLowerCase())
    })
    pagesList.innerHTML = ''
    pagesList.append(...filteredPageLabels)
  }

  const debouncedSearch = debounceSearch(handleSearch, 300)

  inputSearch.addEventListener('keyup', function (event) {
    const { value } = event.target as HTMLInputElement
    debouncedSearch(value)
  })

  const pagesList = document.createElement('ul')
  pagesList.classList.add('mbue-page-search-list')
  pagesList.append(...pageLabels)

  dropdownContainer.appendChild(inputSearch)
  dropdownContainer.appendChild(pagesList)

  iframeDoc.body.appendChild(dropdownContainer)

  const handleClickOutside = (event: MouseEvent) => {
    const pageNumber: HTMLDivElement | null = iframeDoc.querySelector(
      '[data-element="pageNumber"]'
    )
    if (
      dropdownContainer &&
      pageNumber &&
      !dropdownContainer.contains(event.target as Node) &&
      !pageNumber.contains(event.target as Node)
    ) {
      dropdownContainer.classList.remove('mbue-page-search-dropdown--visible')
    }
  }

  iframeDoc.addEventListener('click', handleClickOutside)
}

export const headerCustomizations = (
  webviewerInstance: WebViewerInstance,
  jobManifest: JobManifest,
  jobType: string,
  totalPages: number
) => {
  const iframeDoc = webviewerInstance.UI.iframeWindow.document

  /**
   * @param currentPage
   * @description Update the page label in the header based on the current page number
   */
  const updatePageLabel = (currentPage: number) => {
    let label = `Page ${currentPage}`
    const pageNumber: HTMLDivElement | null = iframeDoc.querySelector(
      '[data-element="pageNumber"]'
    )

    if (!pageNumber) return

    const canRenderPageLabel =
      jobManifest.matches.length > 0 &&
      jobManifest.matches[currentPage - 1].revisedSheetNumber &&
      jobManifest.matches[currentPage - 1].revisedSheetTitle

    if (canRenderPageLabel) {
      label = `${
        jobManifest.matches[currentPage - 1].revisedSheetNumber
      } - ${jobManifest.matches[currentPage - 1].revisedSheetTitle}`
      if (label.length > 40) {
        label = label.substring(0, 40) + '...'
      }
      pageNumber.innerHTML = label
      return
    }

    pageNumber.innerHTML = label
  }

  webviewerInstance.UI.setHeaderItems((header) => {
    const headerItems = header
      .getItems()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .filter((item: Record<string, any>) => item.element !== 'viewControlsOverlay')

    const nextpageButton = {
      type: 'actionButton',
      img: 'icon-chevron-right',
      onClick: () => {
        const pageCount = webviewerInstance.Core.documentViewer.getPageCount()
        const currentPage = webviewerInstance.Core.documentViewer.getCurrentPage()
        if (currentPage === pageCount) return

        webviewerInstance.Core.documentViewer.setCurrentPage(currentPage + 1, false)
        updatePageLabel(currentPage + 1)
      },
      dataElement: 'mbuePageNavigationButtonPrev',
      hidden: ['mobile']
    }

    const prevpageButton = {
      type: 'actionButton',
      img: 'icon-chevron-left',
      onClick: () => {
        const currentPage = webviewerInstance.Core.documentViewer.getCurrentPage()
        if (currentPage === 1) return

        webviewerInstance.Core.documentViewer.setCurrentPage(currentPage - 1, false)
        updatePageLabel(currentPage - 1)
      },
      dataElement: 'mbuePageNavigationButtonNext',
      hidden: ['mobile']
    }

    const pageNumber = {
      type: 'customElement',
      render: () => {
        const params = new URLSearchParams(window.location.search)
        const pageNumberParam = params.get('pageNumber')
          ? parseInt(params.get('pageNumber')!)
          : 0
        const pageNumber =
          pageNumberParam === 0
            ? webviewerInstance.Core.documentViewer.getCurrentPage()
            : pageNumberParam
        let label = `Page ${pageNumber}`
        if (
          jobManifest.matches.length > 0 &&
          jobManifest.matches[pageNumber - 1].revisedSheetNumber &&
          jobManifest.matches[pageNumber - 1].revisedSheetTitle
        ) {
          label = `${
            jobManifest.matches[pageNumber - 1].revisedSheetNumber
          } - ${jobManifest.matches[pageNumber - 1].revisedSheetTitle}`
          if (label.length > 40) {
            label = label.substring(0, 40) + '...'
          }
        }
        return <div data-element="pageNumber">{label}</div>
      }
    }

    const dualScrollButton = {
      type: 'customElement',
      title: 'Dual Scrolling (D)',
      render: () => <DualScrollHeaderButton jobType={jobType} />
    }

    const layerToggles = {
      type: 'customElement',
      dataElement: 'layersToggleMenuButton',
      render: () => {
        const { documentViewer, annotationManager } = webviewerInstance.Core
        const doc = documentViewer.getDocument()
        const wrapperDiv = document.createElement('div')
        wrapperDiv.classList.add('mbue-layer-toggle-container')
        let layersState: Core.Document.LayerContext[] = []

        // Since we're within webviewer, we need to import tailwind. We can use the defaults here.
        const script = document.createElement('script')
        script.src = 'https://cdn.tailwindcss.com'
        wrapperDiv.appendChild(script)

        // Add some custom styles for layout and active color
        const style = document.createElement('style')
        wrapperDiv.appendChild(style)
        style.textContent = `
          .mbue-layer-toggle-container {
            display: flex;
            align-items: center;
            gap: 16px;
            margin-inline-end: 16px;
          }

          .mbue-layer-toggle {
            display: flex;
            align-items: center;
            gap: 6px;
            white-space: nowrap;
          }

          .primary {
            color: var(--view-header-button-active);
          }
        `

        // Order and rename the layers as needed
        const toggles = new Map([
          ['mbue Smart Diff', 'Smart Overlay'],
          ['mbue Smart Overlay', 'Smart Overlay'],
          ['Original', 'Original'],
          ['Revised', 'Revised']
        ])

        // Reorder the layers based on the toggles map
        // If a layer is not in the map, it will be placed at the end
        function getOrderedLayers() {
          return new Promise<Core.Document.LayerContext[]>((resolve) => {
            doc
              .getLayersArray()
              .then((layers) => {
                const unmatchedLayers = layers.filter((layer) => !toggles.has(layer.name))
                const matchedLayers: Core.Document.LayerContext[] = [...toggles.keys()]
                  .map((key) => layers.find((layer) => layer.name === key))
                  .filter((layer) => !!layer)

                resolve([...matchedLayers, ...unmatchedLayers])
              })
              .catch(() => {
                resolve([])
              })
          })
        }

        function getLayerId(layerName: string) {
          return `layer-${layerName.replace(/[^a-z0-9]/gi, '')}`
        }

        // If the layer is the Smart Overlay, toggle the annotations.
        // We only need to call this if the user uses the switches. The built in
        // Apryse checkboxes handles hiding annotations with the layer.
        function toggleAnnotations(layer: Core.Document.LayerContext) {
          const annotations = annotationManager.getAnnotationsList()
          const mbueAnnotations = annotations.filter(
            (annot) => (annot.Author ?? '').toLowerCase() === 'mbue'
          )

          if (layer.name.toLowerCase().includes('mbue smart')) {
            if (layer.visible) annotationManager.showAnnotations(mbueAnnotations)
            else annotationManager.hideAnnotations(mbueAnnotations)
          }
        }

        // While we're rendering react components for this, we are still outside of the react ecosystem
        // We need to update the component state using vanila js
        function updateToggleElements() {
          void getOrderedLayers().then((layers) => {
            layersState = layers

            layersState.forEach((layer) => {
              const state = layer.visible ? 'checked' : 'unchecked'
              const layerId = `#${getLayerId(layer.name)}`
              const buttonEl = wrapperDiv.querySelector(layerId)

              if (buttonEl) {
                const dotEl = buttonEl.firstElementChild
                ;[buttonEl, dotEl].forEach((el) => {
                  el?.setAttribute('data-state', state)
                  if (el?.getAttribute('aria-checked'))
                    el.setAttribute('aria-checked', state)
                })
              }
            })
          })
        }

        documentViewer.addEventListener('pageComplete', () => {
          updateToggleElements()
        })

        // On header render, get the layers and render the toggles
        void getOrderedLayers().then((layers) => {
          layersState = layers

          layersState.forEach((layer) => {
            const layerId = getLayerId(layer.name)
            const layerDiv = document.createElement('div')
            layerDiv.classList.add('mbue-layer-toggle')

            wrapperDiv.appendChild(layerDiv)
            const root = createRoot(layerDiv)

            // to make this cleaner, we'll render our shadcn react
            // components and append the output to our parent div
            root.render(
              <>
                <Label htmlFor={layerId} className="text-sm font-semibold cursor-pointer">
                  {toggles.get(layer.name) ?? layer.name}
                </Label>
                <Switch
                  id={layerId}
                  checked={layer.visible}
                  onCheckedChange={() => {
                    layersState = layersState.map((layerState) => {
                      if (layerState.name === layer.name) {
                        layerState.visible = !layerState.visible
                        toggleAnnotations(layerState)
                      }
                      return layerState
                    })
                    doc.setLayersArray(layersState)
                    documentViewer.refreshAll()
                    documentViewer.updateView()
                    updateToggleElements()
                  }}
                  className="primary h-3.5 w-6 bg-gray-900 data-[state=checked]:bg-current"
                  toggleClassName="bg-white opacity-70 w-2.5 h-2.5 data-[state=checked]:opacity-100 data-[state=checked]:translate-x-2.5"
                />
              </>
            )
          })
        })

        return jobType === 'drawing' ? wrapperDiv : null
      }
    }

    header.update([
      headerItems[0], // viewer menu
      headerItems[2], // left panel button
      headerItems[1], // divider
      headerItems[4], // zoom controls
      headerItems[14], // Marquee Zoom Tool
      headerItems[5], // Annotations Edit - Arrow Tool
      headerItems[6], // Pan - Hand tool
      headerItems[7], // layers menu
      dualScrollButton,
      headerItems[3], // divider
      prevpageButton,
      pageNumber,
      nextpageButton,
      headerItems[8], // spacer
      layerToggles,
      headerItems[11], // search panel
      headerItems[12] // notes palen - comments
    ])
  })

  const pageNumber: HTMLDivElement | null = iframeDoc.querySelector(
    '[data-element="pageNumber"]'
  )

  const prevNextButtons: NodeListOf<HTMLButtonElement> | null =
    iframeDoc.querySelectorAll('[data-element^="mbuePageNavigationButton"]')

  prevNextButtons.forEach((navButton) => {
    navButton.style.backgroundColor = '#1e1e1e'
    navButton.style.width = '24px'
    navButton.style.height = '24px'
    navButton.style.borderRadius = '4px'
    navButton.style.display = 'flex'
    navButton.style.justifyContent = 'center'
    navButton.style.alignItems = 'center'
    const icon: HTMLDivElement | null = navButton.querySelector('.Icon')
    if (icon) {
      icon.style.width = '18px'
      icon.style.height = '18px'
    }
  })

  if (pageNumber) {
    pageNumber.style.backgroundColor = '#1e1e1e'
    pageNumber.style.width = '294px'
    pageNumber.style.paddingInline = '12px'
    pageNumber.style.height = '24px'
    pageNumber.style.borderRadius = '4px'
    pageNumber.style.display = 'flex'
    pageNumber.style.justifyContent = 'center'
    pageNumber.style.alignItems = 'center'
    pageNumber.style.fontSize = '12px'
    pageNumber.style.overflow = 'hidden'
    pageNumber.style.textOverflow = 'ellipsis'
    pageNumber.style.textWrap = 'nowrap'
    pageNumber.style.whiteSpace = 'nowrap'
    pageNumber.style.cursor = 'pointer'

    pageNumber.addEventListener('click', () => {
      if (dropdownContainer && inputSearch) {
        const isVisible = dropdownContainer.classList.toggle(
          'mbue-page-search-dropdown--visible'
        )

        if (isVisible) {
          dropdownContainer.style.left = `${pageNumber.getBoundingClientRect().left - 28}px`
          inputSearch.focus()
          inputSearch.value = ''
          inputSearch.dispatchEvent(new KeyboardEvent('keyup'))
        }
      }
    })
  }

  // Move all the page navigation controls into a wrapper to be centered all together
  const pageNavigationControlsWrapper: HTMLDivElement = document.createElement('div')
  pageNavigationControlsWrapper.classList.add('page-navigation-controls-wrapper')
  pageNavigationControlsWrapper.style.display = 'flex'
  pageNavigationControlsWrapper.style.gap = '4px'

  const controls = [prevNextButtons[0], pageNumber, prevNextButtons[1]]
  controls[0]?.parentNode?.insertBefore(pageNavigationControlsWrapper, controls[0])
  controls.forEach((control) => {
    pageNavigationControlsWrapper.appendChild(control!)
  })

  pageNavigationControlsWrapper.style.position = 'absolute'
  pageNavigationControlsWrapper.style.left = '50%'
  pageNavigationControlsWrapper.style.transform = 'translateX(-50%)'

  window.addEventListener('resize', () => {
    dropdownContainer?.classList.remove('mbue-page-search-dropdown--visible')
    if (window.innerWidth > 1366) {
      pageNavigationControlsWrapper.style.position = 'absolute'
      pageNavigationControlsWrapper.style.left = '50%'
      pageNavigationControlsWrapper.style.transform = 'translateX(-50%)'
    } else {
      pageNavigationControlsWrapper.style.position = 'static'
      pageNavigationControlsWrapper.style.left = '0'
      pageNavigationControlsWrapper.style.transform = 'none'
    }
  })

  pageSearchDropdown(
    webviewerInstance,
    iframeDoc,
    jobManifest,
    totalPages,
    updatePageLabel
  )

  // This is a workaround to place the newest on the right side and keep the comments arrows
  // pointing to the comments in the newests
  if (jobType === 'text') {
    const multiViewerContainer: HTMLDivElement | null =
      iframeDoc.querySelector('.MultiViewer')
    if (multiViewerContainer) {
      multiViewerContainer.style.flexDirection = 'row-reverse'
    }
  }
}
