import { useSearchParams } from 'react-router-dom'
import { useFetchDrawingSets, useCreateReview } from '~/hooks/procoreHooks'
import { useProjectQuery } from '~/graphql/hooks/useProjectQuery'
import moment from 'moment'
import { toast } from 'sonner'

import ProcoreHeader from '~/components/Procore/Header'
import Label from '~/components/ui/Label'
import Checkbox from '~/components/ui/Checkbox'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FileImageIcon, InfoIcon, LoaderCircleIcon, FileTextIcon } from 'lucide-react'
import { Alert, AlertTitle, AlertDescription } from '~/components/ui/Alert'
import Button from '~/components/ui/Button'
import Loading from '~/components/ui/Loading'
import { useEffect, useRef } from 'react'

import { type CreateReviewParams } from '~/lib/mbue-api/services/reviews'

export default function Procore() {
  const drawingSetsRef = useRef<HTMLUListElement>(null)
  const [searchParams] = useSearchParams()

  const projectId = Number(searchParams.get('project_id'))
  const procoreCompanyId = Number(searchParams.get('company_id'))
  const mbueOrgId = searchParams.get('mbue_org_id')
  const mbueProjectId = searchParams.get('mbue_project_id')

  const { project, loading: isProjectLoading } = useProjectQuery(
    { projectId: Number(mbueProjectId) },
    { skip: !mbueProjectId }
  )

  const { data = { sets: [] }, isLoading: isLoadingDrawings } = useFetchDrawingSets(
    { procoreCompanyId, projectId },
    {
      enabled: Boolean(procoreCompanyId) && Boolean(projectId),
      retry: false
    }
  )

  const { mutateAsync, isPending } = useCreateReview()

  useEffect(() => {
    if ((project?.jobs ?? []).length > 0) {
      location.assign(`procore/org/${mbueOrgId}/project/${mbueProjectId}/reviews`)
    }
  }, [project, mbueOrgId, mbueProjectId])

  const handleCompareClick = async () => {
    if (!drawingSetsRef.current) return
    if (data.sets.length <= 1) return

    const drawingSetsCheckboxElements =
      drawingSetsRef.current.querySelectorAll<HTMLButtonElement>(
        'button[role="checkbox"]'
      )

    const drawinSetsToReviews: CreateReviewParams[] = []

    drawingSetsCheckboxElements.forEach((checkboxEl, index) => {
      if (index === 0) return

      if (checkboxEl.value === 'on') {
        drawinSetsToReviews.push({
          procoreCompanyId,
          projectId,
          drawingSetId: Number(checkboxEl.id)
        })
      }
    })

    const createReviewsPromises = drawinSetsToReviews.map((setToReview) =>
      mutateAsync(setToReview)
    )

    const results = await Promise.allSettled(createReviewsPromises)

    if (results.every((createdReview) => createdReview.status === 'fulfilled')) {
      toast.success('All reviews have been created successfully')
      location.assign(`procore/org/${mbueOrgId}/project/${mbueProjectId}/reviews`)
      return
    }

    const rejectedResults = results.reduce<string[]>((accum, curr, index) => {
      if (curr.status === 'rejected') {
        return [...accum, drawinSetsToReviews[index].drawingSetId.toString()]
      }

      return accum
    }, [])

    toast.error(
      'There was an error creating the following drawing sets: ' +
        rejectedResults.join(', ')
    )

    results
      .filter((result) => result.status === 'rejected')
      .forEach((rejected) => console.error(rejected.reason))
  }

  const isLoading = isLoadingDrawings || isProjectLoading

  return (
    <>
      <ProcoreHeader />

      {isLoading && <Loading />}

      {!isLoading && (
        <main className="mt-[48px] px-6">
          <section className="h-[72px] flex justify-between items-center border-b border-divider">
            <div>
              <h1 className="text-xl text-content font-semibold">Reviews</h1>
            </div>
          </section>

          <section className="pt-4">
            <Alert variant={'warning'}>
              <InfoIcon className="h-4 w-4" />
              <AlertTitle>Heads up!</AlertTitle>
              <AlertDescription>
                Please select the drawings and specifications you&apos;d like to compare
                to get started. Each will be compared with the previous revision.
              </AlertDescription>
            </Alert>
          </section>

          <div className="flex gap-4">
            <section className="pt-4 w-1/2">
              <div className="grid gap-2">
                <h2 className="text-lg flex items-center gap-2 text-content font-semibold">
                  <FileImageIcon size={18} />
                  <span>Drawing Sets</span>
                </h2>
                <ul ref={drawingSetsRef}>
                  {data.sets.map((set, i) => (
                    <li className="flex items-center gap-2 py-1" key={set.id}>
                      <Checkbox
                        id={set.id.toString()}
                        defaultChecked={true}
                        className={i > 0 ? '' : 'invisible'}
                      />
                      <Label
                        htmlFor={set.id.toString()}
                        className={`font-normal ${i === 0 ? 'text-disabled' : ''}`}
                      >
                        {set.name} | created at:{' '}
                        {moment(set.created_at).format('MM/DD/YYYY HH:mm:ss')}
                      </Label>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex items-center gap-2 pt-6">
                <Checkbox id={`automatic-review`} defaultChecked={true} />
                <Label htmlFor={`automatic-review`} className="font-semibold">
                  Automatically run comparisons on newly uploaded drawings sets
                </Label>
              </div>
            </section>

            {/* <section className="pt-4 w-1/2">
              <div className="grid gap-2">
                <h2 className="text-lg flex items-center gap-2 text-content font-semibold">
                  <FileTextIcon size={18} />
                  <span>Specifications</span>
                </h2>
                <ul>
                  {Array.from({ length: 10 }).map((_, i) => (
                    <li className="flex items-center gap-2 py-1" key={i}>
                      <Checkbox
                        id={`spec-0${i}`}
                        checked
                        className={i > 0 ? '' : 'invisible'}
                      />
                      <Label
                        htmlFor={`spec-0${i}`}
                        className={`font-normal ${i === 0 ? 'text-disabled' : ''}`}
                      >
                        Contract Specs | Date: 05/13/2023
                      </Label>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex items-center gap-2 pt-6">
                <Checkbox id={`automatic-review`} checked />
                <Label htmlFor={`automatic-review`} className="font-semibold">
                  Automatically run comparisons on newly created specifications.
                </Label>
              </div>
            </section> */}
          </div>

          <section className="pt-4 flex justify-center">
            <Button
              onClick={() => void handleCompareClick()}
              disabled={data.sets.length <= 1 || isPending}
            >
              {isPending && <LoaderCircleIcon className="mr-2 h-5 w-5 animate-spin" />}
              Compare
            </Button>
          </section>
        </main>
      )}
    </>
  )
}
