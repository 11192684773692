import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from 'gql/ApolloProvider.tsx'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { env } from './env'
import App from '~/app/App.tsx'
import '~/index.css'
import { Toaster } from '~/components/ui/Toaster'
import { EnvBadge } from './components/EnvBadge'
import FilesCache from '~/lib/FilesCache.sw'

FilesCache.init()
const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={env.VITE_GOOGLE_OAUTH_CLIENT_ID}>
      <BrowserRouter>
        <ApolloProvider>
          <QueryClientProvider client={queryClient}>
            <App />
            <Toaster />
            <EnvBadge />
          </QueryClientProvider>
        </ApolloProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>
)
