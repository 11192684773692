import { useMutation, gql } from '@apollo/client'
import { MutationHookParams } from 'gql/sharedTypes/types'

const CREATE_ORGANIZATION_MUTATION = gql`
  mutation ($input: CreateOrgInput!) {
    createOrg(input: $input)
  }
`

export interface CreateOrganizationResponse {
  createOrg: boolean
}

export interface CreateOrganizationInput {
  name: string
}

export const useCreateOrganizationMutation = (options?: MutationHookParams) => {
  const [mutation, { data, loading, error }] = useMutation<CreateOrganizationResponse>(
    CREATE_ORGANIZATION_MUTATION,
    options
  )

  const createOrganization = (createOrganizationData: CreateOrganizationInput) =>
    mutation({
      variables: {
        input: createOrganizationData
      }
    })

  return {
    createOrganization,
    data,
    loading,
    error
  }
}
