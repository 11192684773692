import { ColumnDef, RowData } from '@tanstack/react-table'
import { JobStatus, ReviewStatus } from './types'
import moment from 'moment'
import JobsActionDropdown from './JobsActionDropdown'
import { JobRowData } from './types'
import QualityStatusSelect from './QualityStatusSelect'
import CommentTextarea from './CommentTextarea'

export const getColumns = () => {
  const columns: ColumnDef<RowData>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      cell: ({ row: { original } }) => {
        const job = original as JobRowData
        return (
          <span
            className={
              job.deletedAt
                ? 'text-red-500 line-through'
                : job.employee
                  ? 'text-blue-500'
                  : ''
            }
          >
            {job.id}
          </span>
        )
      }
    },
    {
      accessorKey: 'userEmail',
      header: 'UserEmail'
    },
    {
      accessorKey: 'orgName',
      header: 'Org'
    },
    {
      accessorKey: 'project.name',
      header: 'Project'
    },
    {
      accessorKey: 'jobType',
      header: 'Type'
    },
    {
      accessorKey: 'revisedDesign.pageCount',
      header: '#'
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row: { original } }) => {
        const job = original as JobRowData
        let colorClass = 'text-yellow-500'
        const status = JobStatus[job.status]
        if (status === 'COMPLETE') colorClass = 'text-green-500'
        if (status.includes('ERROR')) colorClass = 'text-red-500'

        return <span className={colorClass}>{status}</span>
      }
    },
    {
      accessorKey: 'createdAt',
      header: 'Started At',
      cell: ({ row: { original } }) => {
        const job = original as JobRowData
        let duration = 0
        if (job.startedAt) {
          const start = moment(job.startedAt)
          const end = moment(job.completedAt)
          duration = end.diff(start, 'minutes')
        } else if (job.completedAt) {
          const start = moment(job.createdAt)
          const end = moment(job.completedAt)
          duration = end.diff(start, 'minutes')
        }

        return (
          <>
            {moment(job.startedAt ?? job.createdAt).format('MMM DD, YYYY hh:mm A')}
            {duration > 0 && (
              <span className="text-xs pl-1 text-gray-500"> ({duration} mins)</span>
            )}
          </>
        )
      }
    },
    {
      header: '',
      accessorKey: 'actions',
      cell: ({ row: { original } }) => {
        const job = original as JobRowData
        return <JobsActionDropdown job={job} />
      }
    },
    {
      header: 'Review State',
      accessorKey: 'internalQCReview.reviewStatus',
      enableSorting: true,
      cell: ({ row: { original } }) => {
        const job = original as JobRowData
        const status: number = job.internalQCReview?.reviewStatus ?? 0
        if (status > 0) {
          return ReviewStatus[status].toLowerCase()
        }
      }
    },
    {
      header: 'Reviewed At',
      accessorKey: 'internalQCReview.date',
      enableSorting: true,
      cell: ({ row: { original } }) => {
        const job = original as JobRowData
        if (job.internalQCReview?.date) {
          return moment(job.internalQCReview.date).format('MMM DD, YYYY hh:mm A')
        }
      }
    },
    {
      header: 'Reviewer',
      accessorKey: 'internalQCReview.reviewer',
      enableSorting: true,
      cell: ({ row: { original } }) => {
        const job = original as JobRowData
        if (job.internalQCReview?.reviewer) {
          const initials = String(job.internalQCReview.reviewer)
            .substring(0, 2)
            .toUpperCase()
          return (
            <span
              title={job.internalQCReview.reviewer}
              className="rounded text-sm text-white bg-slate-500 p-1"
            >{`${initials}`}</span>
          )
        }
      }
    },
    {
      header: 'Quality',
      accessorKey: 'internalQCReview.qualityStatus',
      enableSorting: true,
      cell: ({ row: { original } }) => {
        const job = original as JobRowData
        if (job.internalQCReview?.qualityStatus) {
          return <QualityStatusSelect job={job} />
        }
      }
    },
    {
      header: 'Comments',
      accessorKey: 'internalQCReview.comment',
      enableSorting: true,
      cell: ({ row: { original } }) => {
        const job = original as JobRowData
        return <CommentTextarea job={job} />
      }
    }
  ]

  return columns
}
