import mixpanel, { Dict } from 'mixpanel-browser'
import { env } from '~/env'

const isProd = env.VITE_ENV === 'prod'

interface IdentifyParams {
  id: string
  data?: Dict
}

interface TrackParams {
  event: string
  data?: Dict
}
export class Mixpanel {
  private static instance: Mixpanel

  public static getInstance(): Mixpanel {
    if (!Mixpanel.instance) {
      this.instance = new Mixpanel()
    }

    return this.instance
  }

  public constructor() {
    if (Mixpanel.instance) {
      throw new Error(
        'Instance creation of Mixpanel is not allowed, Plesae use Mixpanel.getInstance()'
      )
    }

    if (isProd) {
      mixpanel.init(env.VITE_MIXPANEL_TOKEN, {
        debug: env.VITE_ENV !== 'prod',
        track_pageview: false,
        persistence: 'localStorage'
      })
    }
  }

  public identify({ id, data }: IdentifyParams) {
    if (!isProd) return

    mixpanel.identify(id)

    if (data) {
      mixpanel.people.set_once(data)
    }
  }

  public track({ event, data }: TrackParams) {
    if (!isProd) return
    mixpanel.track(event, data)
  }

  public reset() {
    if (!isProd) return
    mixpanel.reset()
  }
}

export default Mixpanel.getInstance()
