import { gql, QueryHookOptions, useQuery } from '@apollo/client'

const PAGE_SCROLL_MAP_QUERY = gql`
  query GetPageScrollMap($jobId: Int!) {
    getPageScrollMap(jobId: $jobId) {
      data {
        originalLength
        revisedLength
        pageChangeMap
        ranges {
          ... on MappedRange {
            __typename
            original {
              start {
                pageIx
                offset
              }
              end {
                pageIx
                offset
              }
            }
            revised {
              start {
                pageIx
                offset
              }
              end {
                pageIx
                offset
              }
            }
          }
          ... on AddedRange {
            __typename
            revised {
              start {
                pageIx
                offset
              }
              end {
                pageIx
                offset
              }
            }
          }
          ... on RemovedRange {
            __typename
            original {
              start {
                pageIx
                offset
              }
              end {
                pageIx
                offset
              }
            }
          }
        }
        originalPageHeights
        revisedPageHeights
      }
      error
      success
    }
  }
`

export interface ReportChanges {
  data: Record<string, string | number>
}

export interface PagePosition {
  pageIx: number
  offset: number
}

export interface ScrollRange {
  start: PagePosition
  end: PagePosition
}

export interface MappedRange {
  original: ScrollRange
  revised: ScrollRange
}

export interface AddedRange {
  revised: ScrollRange
}

export interface RemovedRange {
  original: ScrollRange
}

export type Range = MappedRange | AddedRange | RemovedRange

export interface PageScrollMapResponse {
  getPageScrollMap: {
    data: {
      originalLength: number
      revisedLength: number
      pageChangeMap: [number, number][]
      ranges: Range[]
      originalPageHeights: Record<number | 'null', number>
      revisedPageHeights: Record<number | 'null', number>
    }
    error: string | null
    success: boolean
  }
}

export const usePageScrollMapQuery = (id: number, options?: QueryHookOptions) => {
  const { data, loading, error, refetch } = useQuery<PageScrollMapResponse>(
    PAGE_SCROLL_MAP_QUERY,
    {
      variables: {
        jobId: id
      },
      ...options
    }
  )

  return {
    pageScrollMap: data?.getPageScrollMap.data,
    loading,
    error,
    refetch
  }
}

export type PageScrollMapQuery = ReturnType<typeof usePageScrollMapQuery>
