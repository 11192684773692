import { useEffect, useState } from 'react'
import { ColumnDef, RowData } from '@tanstack/react-table'
import { getColumns } from './columns'
import { useAdmin, AdminJobsFilter, AdminJobRecord } from '~/graphql/hooks/useAdmin'
import { JobFormValues, defaultFilters } from './types'
import { JobsFilterForm } from './JobsFilterForm'
import { JobsTable } from './JobsTable'
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious
} from '~/components/ui/Pagination/Pagination'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuGroup,
  DropdownMenuItem
} from 'components/ui/DropdownMenu'
import { SettingsIcon } from 'lucide-react'
import { useUser } from '~/graphql/hooks/useUser'
import NewOrgModal from '~/components/Organization/NewOrgModal'

export default function Jobs() {
  const [filter, setFilter] = useState<AdminJobsFilter>(defaultFilters)
  const [data, setData] = useState<AdminJobRecord[]>([])
  const [jobColumns, setJobColumns] = useState<ColumnDef<RowData>[]>([])
  const { jobs, loading: loadingJobs } = useAdmin()
  const { user } = useUser()

  useEffect(() => {
    window.addEventListener('RerunSearch', () => void rerunSearch())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rerunSearch = async () => {
    setData(await jobs(filter))
  }

  const handlePreviousPageClicked = async () => {
    filter.page = filter.page > 1 ? filter.page - 1 : 1
    setFilter(filter)
    setData(await jobs(filter))
  }

  const handleNextPageClicked = async () => {
    if (data.length === filter.limit) {
      filter.page = filter.page + 1
    }
    setFilter(filter)
    setData(await jobs(filter))
  }

  const onJobFormSubmit = async (data: JobFormValues) => {
    filter.page = 1
    filter.filters.employee = data.employee ?? defaultFilters.filters.employee
    filter.filters.deleted = data.deleted ?? defaultFilters.filters.deleted
    const jobId = parseInt(data.jobId, 10)
    filter.filters.jobId = jobId
    filter.filters.email = data.email
    filter.filters.emailSearchType = data.emailSearchType
    filter.limit = parseInt(data.pageLimit, 10)
    filter.filters.jobType = data.jobType

    setFilter(filter)
    setData(await jobs(filter))
  }

  useEffect(() => {
    setJobColumns(getColumns())
  }, [data])

  return (
    <>
      {user?.isAdmin && (
        <div className="flex min-h-screen w-full flex-col bg-white bg-muted/40 pt-3">
          <div className="flex flex-col sm:gap-4 sm:py-4">
            <main className="grid flex-1 items-start gap-2 p-2 sm:px-6 sm:py-0 md:gap-3">
              <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold">Admin Dashboard</h1>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <button className="border border-divider rounded-sm p-1 hover:bg-muted-foreground/5">
                      <SettingsIcon color="#414141" size={20} />
                    </button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent sideOffset={6} align={'end'}>
                    <DropdownMenuLabel>Platform Settings</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                      <DropdownMenuItem
                        onSelect={() => {
                          document.getElementById('new-org-button')?.click()
                        }}
                      >
                        Create an Organization
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              <JobsFilterForm
                handleSubmitCallback={(data) => void onJobFormSubmit(data)}
              />
              {data.length > 0 && (
                <Pagination>
                  <PaginationContent>
                    {filter.page > 1 && (
                      <PaginationItem>
                        <PaginationPrevious
                          onClick={() => void handlePreviousPageClicked()}
                        />
                      </PaginationItem>
                    )}
                    <PaginationItem> {filter.page} </PaginationItem>
                    {data.length === filter.limit && (
                      <PaginationItem>
                        <PaginationNext onClick={() => void handleNextPageClicked()} />
                      </PaginationItem>
                    )}
                  </PaginationContent>
                </Pagination>
              )}
              <div className="overflow-auto">
                <JobsTable columns={jobColumns} data={data} loadingData={loadingJobs} />
              </div>
            </main>
          </div>
        </div>
      )}
      <NewOrgModal
        trigger={
          <button id="new-org-button" className="invisible">
            New an Organization
          </button>
        }
      />
    </>
  )
}
