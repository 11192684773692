import {
  useQuery,
  useMutation,
  type UseQueryOptions,
  type UseMutationOptions
} from '@tanstack/react-query'
import { auth, type AuthParams } from '~/lib/mbue-api/services/auth'
import {
  getDrawingSets,
  type DrawingSetsParams
} from '~/lib/mbue-api/services/drawingSets'
import { createReview, type CreateReviewParams } from '~/lib/mbue-api/services/reviews'

import {
  type Auth,
  type DrawingSetResponse,
  type CreateReviewResponse
} from '~/lib/mbue-api/types'

export function useProcoreAuth(
  authParams: AuthParams,
  options?: UseQueryOptions<Auth, Error>
) {
  return useQuery({
    queryKey: ['procoreAuth', authParams],
    queryFn: () => auth(authParams),
    enabled: false,
    retry: false,
    ...options
  })
}

export function useFetchDrawingSets(
  queryParams: DrawingSetsParams,
  options?: Omit<UseQueryOptions<DrawingSetResponse, Error>, 'queryKey' | 'queryFn'>
) {
  return useQuery({
    queryKey: ['procoreDrawingSets', queryParams],
    queryFn: () => getDrawingSets(queryParams),
    ...options
  })
}

export function useCreateReview(
  options?: UseMutationOptions<CreateReviewResponse, Error, CreateReviewParams>
) {
  return useMutation({
    mutationFn: (params: CreateReviewParams) => createReview(params),
    ...options
  })
}
