import {
  AddedRange,
  MappedRange,
  RemovedRange,
  Range
} from '../../graphql/hooks/usePageScrollMap'

/**
 * Builds a mapping between original and revised pages based on the provided ranges.
 *
 * @param ranges - An array of Range objects defining how pages are mapped.
 * @param originalSizes - Record of original document page sizes.
 * @param revisedSizes - Record of revised document page sizes.
 * @param isOriginalToRevised - Flag to determine the direction of mapping.
 * @returns A record mapping original pages to revised pages or vice versa.
 */
export function buildPageAlignmentMap(
  ranges: Range[],
  originalSizes: Record<number | 'null', number>,
  revisedSizes: Record<number | 'null', number>,
  isOriginalToRevised = false
): Record<number, number> {
  const result: Record<number, number> = {}

  // Calculate max pages from ranges
  const maxOriginalPage = ranges.reduce((max, range) => {
    if ('original' in range) {
      return Math.max(max, range.original.end.pageIx)
    }
    return max
  }, 0)

  const maxRevisedPage = ranges.reduce((max, range) => {
    if ('revised' in range) {
      return Math.max(max, range.revised.end.pageIx)
    }
    return max
  }, 0)

  const [originalPageRanges, revisedPageRanges] = getLastMappedPage(ranges)

  if (isOriginalToRevised) {
    // Map original pages to revised pages
    buildDirectionalMap(
      maxOriginalPage,
      originalPageRanges,
      'original',
      'revised',
      result
    )
  } else {
    // Map revised pages to original pages
    buildDirectionalMap(maxRevisedPage, revisedPageRanges, 'revised', 'original', result)
  }

  // Convert result to be one-indexed
  return Object.fromEntries(
    Object.entries(result).map(([key, value]) => [Number(key) + 1, value + 1])
  )
}

/**
 * Helper function to build directional mapping between pages
 *
 * @param maxPage - Maximum page number to map
 * @param pageRanges - Map of page numbers to their affecting ranges
 * @param sourceSide - The side we're mapping from ('original' or 'revised')
 * @param targetSide - The side we're mapping to ('revised' or 'original')
 * @param result - The result map to populate
 */
function buildDirectionalMap(
  maxPage: number,
  pageRanges: Map<number, Range>,
  sourceSide: 'original' | 'revised',
  targetSide: 'revised' | 'original',
  result: Record<number, number>
): void {
  let lastMappedPage = 0

  for (let page = 0; page <= maxPage; page++) {
    const range = pageRanges.get(page)
    if (range) {
      if (isMappedRange(range)) {
        // Calculate the offset within the source range
        const offsetInSource = page - range[sourceSide].start.pageIx
        // Map to the corresponding position in the target range
        result[page] = Math.min(
          range[targetSide].start.pageIx + offsetInSource,
          range[targetSide].end.pageIx
        )
        lastMappedPage = range[targetSide].end.pageIx
      } else if (
        (sourceSide === 'original' && isRemovedRange(range)) ||
        (sourceSide === 'revised' && isAddedRange(range))
      ) {
        // Map special pages (added/removed) to the previous valid target page
        result[page] = lastMappedPage
      }
    } else {
      // If no range affects this page, map it to the next available page
      if (sourceSide === 'original') {
        result[page] = lastMappedPage
      } else {
        result[page] = lastMappedPage
      }
    }
  }
}

export function getLastMappedPage(
  ranges: Range[]
): [Map<number, Range>, Map<number, Range>] {
  // Create maps to store the last range affecting each page
  const originalRangesPerPage = new Map<number, Range[]>()
  const revisedRangesPerPage = new Map<number, Range[]>()
  const originalPageRanges = new Map<number, Range>()
  const revisedPageRanges = new Map<number, Range>()

  // Populate the maps with the ranges affecting each page
  for (const range of ranges) {
    if (isMappedRange(range)) {
      const originalPages = Array.from(
        { length: range.original.end.pageIx - range.original.start.pageIx + 1 },
        (_, i) => range.original.start.pageIx + i
      )
      for (const page of originalPages) {
        if (originalRangesPerPage.has(page)) {
          originalRangesPerPage.get(page)?.push(range)
        } else {
          originalRangesPerPage.set(page, [range])
        }
      }
      const revisedPages = Array.from(
        { length: range.revised.end.pageIx - range.revised.start.pageIx + 1 },
        (_, i) => range.revised.start.pageIx + i
      )
      for (const page of revisedPages) {
        if (revisedRangesPerPage.has(page)) {
          revisedRangesPerPage.get(page)?.push(range)
        } else {
          revisedRangesPerPage.set(page, [range])
        }
      }
    } else if (isAddedRange(range)) {
      const startPage = range.revised.start.pageIx
      const endPage = range.revised.end.pageIx || startPage
      const revisedPages = Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      )
      for (const page of revisedPages) {
        if (revisedRangesPerPage.has(page)) {
          revisedRangesPerPage.get(page)?.push(range)
        } else {
          revisedRangesPerPage.set(page, [range])
        }
      }
    } else if (isRemovedRange(range)) {
      const startPage = range.original.start.pageIx
      const endPage = range.original.end.pageIx || startPage
      const originalPages = Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      )
      for (const page of originalPages) {
        if (originalRangesPerPage.has(page)) {
          originalRangesPerPage.get(page)?.push(range)
        } else {
          originalRangesPerPage.set(page, [range])
        }
      }
    }
  }

  // Convert the maps to be maps of page the last mapped range affecting it
  // Unless the page only has added/removed range, in which case it's the last range affecting it
  // Or the page has no ranges affecting it, in which case it's the last range affecting the next page
  for (const page of originalRangesPerPage.keys()) {
    const ranges = originalRangesPerPage.get(page)
    if (ranges) {
      if (ranges.length === 1 && (isAddedRange(ranges[0]) || isRemovedRange(ranges[0]))) {
        originalPageRanges.set(page, ranges[0])
      } else {
        // Find the last mapped range affecting the page
        const lastMappedRange = ranges.reverse().find((range) => isMappedRange(range))
        if (lastMappedRange) {
          originalPageRanges.set(page, lastMappedRange)
        }
      }
    }
  }
  for (const page of revisedRangesPerPage.keys()) {
    const ranges = revisedRangesPerPage.get(page)
    if (ranges) {
      if (ranges.length === 1 && (isAddedRange(ranges[0]) || isRemovedRange(ranges[0]))) {
        revisedPageRanges.set(page, ranges[0])
      } else {
        // Find the last mapped range affecting the page
        const lastMappedRange = ranges.reverse().find((range) => isMappedRange(range))
        if (lastMappedRange) {
          revisedPageRanges.set(page, lastMappedRange)
        }
      }
    }
  }
  return [originalPageRanges, revisedPageRanges]
}

/**
 * Type guard to check if a range is a MappedRange.
 *
 * @param range - The range to check.
 * @returns True if the range is a MappedRange, else false.
 */
function isMappedRange(range: Range): range is MappedRange {
  return 'original' in range && 'revised' in range
}

/**
 * Type guard to check if a range is an AddedRange.
 *
 * @param range - The range to check.
 * @returns True if the range is an AddedRange, else false.
 */
function isAddedRange(range: Range): range is AddedRange {
  return 'revised' in range && !('original' in range)
}

/**
 * Type guard to check if a range is a RemovedRange.
 *
 * @param range - The range to check.
 * @returns True if the range is a RemovedRange, else false.
 */
function isRemovedRange(range: Range): range is RemovedRange {
  return 'original' in range && !('revised' in range)
}

/**
 * This is backwards compatibility for the pageChangeMap field.
 * Expands a minimal page-scroll map into a full list of (originalPage, revisedPage) pairs
 * that indicate which pages should be displayed together.
 */
export function buildFullPageMap(
  pageScrollData: {
    pageChangeMap: [number, number][]
    originalLength: number
    revisedLength: number
  },
  newestToOriginal = true
): Record<number, number> {
  if (!pageScrollData.pageChangeMap || pageScrollData.pageChangeMap.length === 0) {
    return {}
  }
  const rightSideLength = pageScrollData.revisedLength
  let pageMap = pageScrollData.pageChangeMap
  // Create a map of the revised pages to the original pages
  const result: Record<number, number> = {}
  let currOriginal = 0
  let currRevised = 0
  while (currRevised < rightSideLength) {
    // Find any mapping that matches current indices
    const matchIndex = pageMap.findIndex(
      ([orig, rev]) => orig === currOriginal || rev === currRevised
    )

    if (matchIndex !== -1) {
      const [origPage, revPage] = pageMap[matchIndex]
      // Align both positions to the mapped pages
      currOriginal = origPage
      currRevised = revPage
      // Remove used mapping
      pageMap = pageMap.filter((_, index) => index !== matchIndex)
    }

    // Map current revised page to original
    result[currRevised] = currOriginal

    // Move both positions forward together
    currOriginal++
    currRevised++
  }

  if (!newestToOriginal) {
    // Swap keys and values, keeping first occurrence of each key
    const swapped: Record<number, number> = {}
    Object.entries(result).forEach(([key, value]) => {
      if (!(value in swapped)) {
        swapped[value] = Number(key)
      }
    })
    return Object.fromEntries(
      Object.entries(swapped).map(([key, value]) => [Number(key) + 1, value + 1])
    )
  }
  // Convert the result to be one-indexed
  return Object.fromEntries(
    Object.entries(result).map(([key, value]) => [Number(key) + 1, value + 1])
  )
}
