import { useCallback } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useLocalStorage } from '@uidotdev/usehooks'

export interface UserPlan {
  name: string
}

export interface UserOrg {
  id: number
  name: string
  type: string
  role: string
}

export interface User {
  id: number
  firstName: string
  lastName: string
  email: string
  trial: boolean
  isAdmin: boolean
  emailList: boolean
  profileRole: string
  authenticatedFrom: string
  plan: UserPlan
  orgs: UserOrg[]
  provider: string
  matchedOrg: {
    id: number
    name: string
    projectCount: number
    owner: string
  }
}

const USER_QUERY = gql`
  query MeQuery {
    me {
      id
      firstName
      lastName
      email
      trial
      isAdmin
      emailList
      profileRole
      authenticatedFrom
      provider
      plan {
        name
      }
      orgs {
        id
        name
        type
        role
      }
      matchedOrg {
        id
        name
        projectCount
        owner
      }
    }
  }
`

export function useUser() {
  const [cachedUserData, setCachedUserData] = useLocalStorage<User | null>(
    'user_data',
    null
  )

  const { data, loading, error, refetch } = useQuery<{ me: User | null }>(USER_QUERY, {
    onCompleted: (data) => {
      setCachedUserData(data.me)
    },
    skip: Boolean(cachedUserData)
  })

  const refetchUserData = useCallback(async () => {
    try {
      const result = await refetch()
      if (result.data) {
        setCachedUserData(result.data.me)
        return result
      }
    } catch (error) {
      console.error(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const invalidateCache = useCallback(async () => {
    setCachedUserData(null)
    await refetchUserData()
  }, [setCachedUserData, refetchUserData])

  const clearCache = useCallback(() => {
    setCachedUserData(null)
  }, [setCachedUserData])

  return {
    user: cachedUserData ?? data?.me,
    loading,
    error,
    refetchUserData,
    invalidateCache,
    clearCache
  }
}
