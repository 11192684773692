import { httpClient } from '../httpClient'
import { type CreateReviewResponse } from '../types'

export interface CreateReviewParams {
  projectId: number
  drawingSetId: number
  procoreCompanyId: number
}

export async function createReview(params: CreateReviewParams) {
  if (
    params.procoreCompanyId === 0 ||
    params.projectId === 0 ||
    params.drawingSetId === 0
  ) {
    throw new Error('Company Id, Project Id and Drawing Set Id are required')
  }

  const { procoreCompanyId, projectId, drawingSetId } = params

  const response = await httpClient<CreateReviewResponse>(
    `/api/v2/procore/project/${projectId}/drawing-sets/${drawingSetId}/compare?procore_company_id=${procoreCompanyId}`,
    { method: 'POST' } // TODO: update to POST when the endpoint is updated
  )

  return response
}
