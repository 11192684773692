import { env } from '~/env'
import { storage } from '~/utilities/storageUitls'

const BASE_URL = env.VITE_MBUE_API

export async function httpClient<T>(
  endpoint: string,
  options: RequestInit = {}
): Promise<T> {
  const baseUrl = BASE_URL

  const defaultHeaders: HeadersInit = {
    'Content-Type': 'application/json',
    ...(localStorage.getItem('access_token') && {
      Authorization: `Bearer ${storage.getJSON('access_token') as string}`
    })
  }

  try {
    const response = await fetch(`${baseUrl}${endpoint}`, {
      ...options,
      headers: { ...defaultHeaders, ...options.headers }
    })
    if (!response.ok) {
      const errorData = (await response.json()) as { message?: string }
      throw new Error(errorData.message ?? `Error ${response.status}`)
    }

    const data = (await response.json()) as T
    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}
