import { env } from '~/env'
import { httpClient } from '../httpClient'
import { Auth } from '../types'

const PROCORE_REDIRECT_URI = env.VITE_PROCORE_REDIRECT_URI

export interface AuthParams {
  authCode: string
  projectId: number
  companyId: number
}

export async function auth(params: AuthParams) {
  if (params.companyId === 0 || params.projectId === 0) {
    throw new Error('Company Id and Project Id are required')
  }

  const response = await httpClient<Auth>('/api/v2/procore/auth', {
    method: 'POST',
    body: JSON.stringify({
      auth_code: params.authCode,
      redirect_uri: PROCORE_REDIRECT_URI,
      company_id: params.companyId,
      project_id: params.projectId
    })
  })

  return response
}
